import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Nav,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Form,
} from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useStoreState, useStoreStateValue } from '@scena/react-store';
// import moment from 'moment';
import { debounce } from 'lodash';
import {
  $actionManager,
  $historyManager,
  $editor,
  $searchResults,
  $searchResultsIndex,
  $layerManager,
} from '../stores/stores';
// import Constants from '../../../../../constants';

/* const history = [
  moment()
    .subtract(5, 'minutes')
    .format(Constants.DateFormats.APP.Moment.CommonWithTime),
  moment()
    .subtract(10, 'minutes')
    .format(Constants.DateFormats.APP.Moment.CommonWithTime),
  moment()
    .subtract(15, 'minutes')
    .format(Constants.DateFormats.APP.Moment.CommonWithTime),
  moment()
    .subtract(20, 'minutes')
    .format(Constants.DateFormats.APP.Moment.CommonWithTime),
  moment()
    .subtract(25, 'minutes')
    .format(Constants.DateFormats.APP.Moment.CommonWithTime),
]; */

const sections = [
  /* {
    items: [
      {
        id: 'layers',
        titleKey: 'layers',
        icon: 'bi-layers',
      },
    ],
  }, */
  {
    items: [
      {
        id: 'undo',
        titleKey: 'undo',
        icon: 'bi-arrow-counterclockwise',
      },
      {
        id: 'redo',
        titleKey: 'redo',
        icon: 'bi-arrow-clockwise',
      },
    ],
  },
  {
    items: [
      {
        id: 'bringForward',
        titleKey: 'bringForward',
        icon: 'bi-chevron-up',
      },
      {
        id: 'sendBackwards',
        titleKey: 'sendBackwards',
        icon: 'bi-chevron-down',
      },
      {
        id: 'bringToFront',
        titleKey: 'bringToFront',
        icon: 'bi-chevron-double-up',
      },
      {
        id: 'sendToBack',
        titleKey: 'sendToBack',
        icon: 'bi-chevron-double-down',
      },
    ],
  },
  /* {
    items: [
      {
        id: 'group',
        titleKey: 'group',
        icon: 'bi-columns',
      },
      {
        id: 'ungroup',
        titleKey: 'ungroup',
        icon: 'bi-columns-gap',
      },
    ],
  }, */
  {
    items: [
      {
        id: 'delete',
        titleKey: 'delete',
        icon: 'bi-trash',
      },
    ],
  },
  {
    items: [
      {
        id: 'toggleGrid',
        titleKey: 'toggleGrid',
        icon: 'bi-grid-3x3',
      },
    ],
  },
  {
    items: [
      {
        id: 'toggleBackground',
        titleKey: 'toggleBackground',
        icon: 'bi-image-fill',
      },
    ],
  },
];

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <Button
    ref={ref}
    className="btn-icon"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <i className="bi-arrow-counterclockwise" />
  </Button>
));

CustomToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function TopToolbar() {
  const actionManager = useStoreStateValue($actionManager);
  const historyManager = useStoreStateValue($historyManager);
  const editorRef = useStoreStateValue($editor);
  const layerManager = useStoreStateValue($layerManager);
  const [searchResults, setSearchResults] = useStoreState($searchResults);
  const [searchResultsIndex, setSearchResultsIndex] =
    useStoreState($searchResultsIndex);

  const { formatMessage } = useIntl();

  const onSelect = (id) => {
    // const flatten = sections.reduce((prev, v) => [...prev, ...v.items], []);
    // const foundedItem = flatten.find((item) => item.id === id);

    // console.log(foundedItem);

    switch (id) {
      case 'undo':
        actionManager.act('request.history.undo');
        break;
      case 'redo':
        actionManager.act('request.history.redo');
        break;
      case 'toggleGrid':
        editorRef.current.toggleGrid();
        break;
      case 'toggleBackground':
        editorRef.current.toggleBackground();
        break;
      case 'bringForward':
        editorRef.current.bringForwardLayers();
        break;
      case 'sendBackwards':
        editorRef.current.sendBackwardsLayers();
        break;
      case 'bringToFront':
        editorRef.current.bringToFrontLayers();
        break;
      case 'sendToBack':
        editorRef.current.sendToBackLayers();
        break;
      case 'delete':
        actionManager.act('remove.targets');
        break;

      default:
        break;
    }
  };

  const onExport = () => {
    editorRef.current.exportLayers();
  };

  const onUnlock = () => {
    editorRef.current.unlockMap();
  };
  const onDecline = () => {
    editorRef.current.onDecline();
  };
  const handleSearch = (text) => {
    editorRef.current.setSelectedLayers([]);
    if (!text) {
      editorRef.current.clearSearch();
      return;
    }

    const foundedItems = layerManager.layers.filter((item) => {
      const isFounded =
        `${item.title}`.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1 ||
        item.fpeData?.exhibitors?.filter(
          (nItem) =>
            `${nItem.label}`.toLowerCase().indexOf(`${text}`.toLowerCase()) > -1
        ).length > 0;
      return isFounded;
    });

    setSearchResultsIndex(0);
    setSearchResults(foundedItems);
    editorRef.current.highlightLayer(foundedItems, 0);
  };

  const onSearch = debounce((text) => {
    handleSearch(text);
  }, 100);

  const handleSearchIndex = (dir) => {
    let index = searchResultsIndex + dir;

    if (index < 0) {
      index = searchResults.length - 1;
    } else if (index > searchResults.length - 1) {
      index = 0;
    }
    setSearchResultsIndex(index);
    editorRef.current.highlightLayer(searchResults, index);
  };

  const isDisabled = (id) => {
    let disabled = false;

    if (id === 'undo' && historyManager?.undoStack?.length <= 0) {
      disabled = true;
    } else if (id === 'redo' && historyManager?.redoStack?.length <= 0) {
      disabled = true;
    }

    return disabled;
  };
  return (
    <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between mb-3 mb-lg-0">
      <Nav
        as="ul"
        className="nav-compact-icon nav-compact-icon-circle"
        onSelect={onSelect}
      >
        {sections.map((section, sectionIndex) => (
          <React.Fragment key={`${sectionIndex.toString()}`}>
            {section.items.map(({ id, titleKey, icon }, index) => (
              <Nav.Item key={`${index.toString()}`} as="li" className="mx-1">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      <FormattedMessage id={`app.common.${titleKey}`} />
                    </Tooltip>
                  }
                >
                  <Nav.Link
                    eventKey={id}
                    bsPrefix="nav-icon"
                    active={false}
                    disabled={isDisabled(id)}
                    className={
                      isDisabled(id) ? 'bg-transparent text-muted' : ''
                    }
                  >
                    <i className={icon} />
                  </Nav.Link>
                </OverlayTrigger>
              </Nav.Item>
            ))}
            {sectionIndex < sections.length - 1 && (
              <span className="mx-1 border-end" style={{ height: 30 }} />
            )}
          </React.Fragment>
        ))}
      </Nav>

      <div className="d-flex">
        <div className="me-2">
          <div className="input-group input-group-merge">
            <div className="input-group-prepend input-group-text">
              <i className="bi-search" />
            </div>
            <Form.Control
              className={searchResults.length > 1 ? 'pe-2' : ''}
              type="search"
              placeholder={formatMessage({
                id: 'app.common.search',
              })}
              // searchLayers
              // defaultValue={parseSearchParams().search || ''}
              onChange={(e) => {
                if (e.target.value) {
                  onSearch(e.target.value);
                } else {
                  handleSearch(e.target.value);
                }
              }}
            />
            {searchResults.length > 1 && (
              <div className="input-group-text p-0">
                <ButtonGroup>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    className="border-0"
                    onClick={() => {
                      handleSearchIndex(-1);
                    }}
                  >
                    <i className="bi-caret-left" />
                  </Button>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    className="border-0"
                    onClick={() => {
                      handleSearchIndex(1);
                    }}
                  >
                    <i className="bi-caret-right" />
                  </Button>
                </ButtonGroup>
              </div>
            )}
          </div>
        </div>
        <Dropdown align="end">
          <ButtonGroup>
            {editorRef.current.onSave && (
              <Button size="md" onClick={onExport}>
                <FormattedMessage id="app.common.save" />
              </Button>
            )}
            {editorRef.current.onUnlock && (
              <Button size="md" variant="warning" onClick={onUnlock}>
                <FormattedMessage id="app.common.unlock" />
              </Button>
            )}
            {editorRef.current.onDecline && (
              <Button size="md" variant="danger" onClick={onDecline}>
                <FormattedMessage id="app.common.decline" />
              </Button>
            )}
            {/* <ButtonGroup>
              <Dropdown.Toggle size="sm" as={CustomToggle} />
                  </ButtonGroup> */}
          </ButtonGroup>
          {/* <Dropdown.Menu className="m-0" renderOnMount>
            <Dropdown.Header>
              <FormattedMessage id="app.common.history" />
            </Dropdown.Header>
            {history.map((item, index) => (
              <Dropdown.Item key={`history_item_${index.toString()}`}>
                <i className="bi-clock-history dropdown-item-icon" />
                <span>{item}</span>
              </Dropdown.Item>
            ))}
            </Dropdown.Menu> */}
        </Dropdown>
      </div>
    </div>
  );
}

export default TopToolbar;
