import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useContext,
} from 'react';
import {
  Row,
  Col,
  Card,
  Collapse,
  Button,
  Form,
  Badge,
  ButtonGroup,
  Dropdown,
  Image,
} from 'react-bootstrap';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { debounce, lowerCase } from 'lodash';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import {
  HighlightedText,
  PageHeader,
  SmartForm,
  SmartTable,
  Tooltip,
} from '../../../components';
import { yup } from '../../../lib';
import Constants from '../../../constants';
import {
  useAxiosQuery,
  useConfirmModal,
  useFormModal,
  useToast,
} from '../../../hooks';
import { Routes as AppRoutes } from '../../../router/routeMapping';
import PlaceHolderImg from '../../../assets/images/placeholder.png';
import { AuthContext } from '../../../context/AuthContext';

const DEFAULT_UNIT = 'm';

const onImageError = (e) => {
  e.target.src = PlaceHolderImg;
};

const statusOptionLabel = ({ value }) => (
  <div className="d-flex align-items-center">
    <span
      className={`legend-indicator bg-${
        // eslint-disable-next-line no-nested-ternary
        value === '1' ? 'success' : value === '2' ? 'warning' : 'danger'
      }`}
    />
    <FormattedMessage
      id={`app.common.${
        // eslint-disable-next-line no-nested-ternary
        value === '1' ? 'active' : value === '2' ? 'locked' : 'inactive'
      }`}
    />
  </div>
);

const getClassSuffix = (value) => {
  console.log(value);
  switch (value) {
    case 1:
      return 'success';
    case 2:
      return 'warning';
    case 20:
      return 'success';
    case 10:
      return 'cyan';
    default:
      return 'danger';
  }
};

const getStatusMessageId = (status) => {
  switch (status) {
    case 1:
      return 'app.common.active';
    case 2:
      return 'app.common.locked';
    case 10:
      return 'app.common.waitingConfirmation';
    case 20:
      return 'app.common.accepted';
    case 21:
      return 'app.common.declined';
    default:
      return 'app.common.inactive';
  }
};
function FloorPlanChangeRequests() {
  const { role } = useContext(AuthContext);
  const tableRef = useRef();
  const filterFormRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filtersIsVisible, setFiltersIsVisible] = useState(false);
  const [filters, setFilters] = useState({});
  const { formatMessage } = useIntl();

  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });
  const { form } = useFormModal();
  const { showToast } = useToast();

  const service = 'maps';

  const { data: calendarData, isLoading: calendarDataIsLoading } =
    useAxiosQuery({
      url: '/calendar/list',
      params: { quantity: 999999 },
    });

  const onAddNewOrEdit = useCallback(
    async (item) => {
      const fields = [
        {
          cols: [
            {
              key: 'title',
              apiKey: 'title',
              schema: yup.string().required(),
            },
          ],
        },
        {
          cols: [
            {
              key: 'status',
              type: 'react-select',
              options: {
                controller: {
                  props: {
                    options: [
                      { label: 'active', value: '1' },
                      { label: 'inactive', value: '0' },
                      { label: 'locked', value: '2' },
                    ],
                    formatOptionLabel: statusOptionLabel,
                    isClearable: false,
                    isMulti: false,
                    isSearchable: false,
                  },
                },
              },
              schema: yup.string().required(),
            },
          ],
        },
        {
          cols: [
            {
              key: 'event',
              apiKey: 'calendar_id',
              type: 'react-select',
              options: {
                controller: {
                  props: {
                    options: calendarData,
                    getOptionValue: (option) => option.CalendarID,
                    getOptionLabel: (option) => option.Title,
                    isLoading: calendarDataIsLoading,
                    isClearable: true,
                    isMulti: false,
                    isSearchable: true,
                  },
                },
              },
              schema: yup.string().nullable(),
            },
          ],
        },
        {
          cols: [
            {
              key: 'width',
              apiKey: 'width',
              type: 'number',
              suffix: DEFAULT_UNIT,
              label: (
                <Form.Label htmlFor="width">
                  <FormattedMessage id="app.common.width" />
                  <Tooltip
                    content={
                      <FormattedMessage id="app.helpers.floorPlans.form.width" />
                    }
                  >
                    <i className="bi-question-circle text-body ms-1" />
                  </Tooltip>
                </Form.Label>
              ),
              options: {
                controller: {
                  props: {
                    min: 1,
                    step: 1,
                    onKeyDown: (e) => {
                      if (e.key === '.') {
                        e.preventDefault();
                      }
                    },
                    onInput: (e) => {
                      e.target.value = e.target.value.replace(/[^0-9]*/g, '');
                    },
                  },
                },
              },
              schema: yup
                .number()
                .transform((value) =>
                  Number.isNaN(value) || value === null || value === undefined
                    ? 0
                    : value
                )
                .min(1)
                .required(),
            },
            {
              key: 'height',
              apiKey: 'height',
              type: 'number',
              suffix: DEFAULT_UNIT,
              label: (
                <Form.Label htmlFor="height">
                  <FormattedMessage id="app.common.height" />
                  <Tooltip
                    content={
                      <FormattedMessage id="app.helpers.floorPlans.form.height" />
                    }
                  >
                    <i className="bi-question-circle text-body ms-1" />
                  </Tooltip>
                </Form.Label>
              ),
              options: {
                controller: {
                  props: {
                    min: 1,
                    step: 1,
                    onKeyDown: (e) => {
                      if (e.key === '.') {
                        e.preventDefault();
                      }
                    },
                    onInput: (e) => {
                      e.target.value = e.target.value.replace(/[^0-9]*/g, '');
                    },
                  },
                },
              },
              schema: yup
                .number()
                .transform((value) =>
                  Number.isNaN(value) || value === null || value === undefined
                    ? 0
                    : value
                )
                .min(1)
                .required(),
            },
          ],
        },
        {
          cols: [
            {
              key: 'background',
              apiKey: 'image',
              type: 'image',
              schema: yup.mixed(),
            },
          ],
        },
      ];

      const defaultValues = item
        ? {
            title: item.title,
            background: item.image,
            width: item.width,
            height: item.height,
            status: `${item.status}`,
            event: item.calendar_id,
          }
        : { status: '0' };

      const isSuccess = await form({
        title: item ? 'editFloorPlan' : 'newFloorPlan',
        confirmLabel: item ? 'save' : 'create',
        requestUrl: `${service}/${item ? 'update' : 'insert'}`,
        requestParams: item ? { id: item.id } : { unit: DEFAULT_UNIT },
        size: 'lg',
        fields,
        defaultValues,
      });

      if (isSuccess) {
        tableRef.current.reload();

        showToast({
          type: 'success',
          autohide: true,
          title: <FormattedMessage id="app.common.success" />,
          message: (
            <FormattedMessage
              id="app.common.theXWasCreatedSuccessfully"
              values={{
                x: lowerCase(formatMessage({ id: 'app.common.floorPlan' })),
              }}
            />
          ),
        });
      }
    },
    [form, formatMessage, showToast, calendarData, calendarDataIsLoading]
  );

  const onDeleteRow = useCallback(
    async (id, title) => {
      const isSuccess = await confirm({
        message: (
          <FormattedMessage
            id="app.common.areYouSureToDeleteTheXY"
            values={{
              x: title,
              y: lowerCase(
                formatMessage({ id: 'app.common.floorPlanChangeRequest' })
              ),
            }}
          />
        ),
        requestUrl: '/maps/delete_assistant_changes',
        requestParams: { id },
      });
      if (isSuccess) {
        tableRef.current.reload();

        showToast({
          type: 'success',
          autohide: true,
          title: <FormattedMessage id="app.common.success" />,
          message: (
            <FormattedMessage
              id="app.common.theXWasDeletedSuccessfully"
              values={{
                x: lowerCase(formatMessage({ id: 'app.common.floorPlan' })),
              }}
            />
          ),
        });
      }
    },
    [confirm, formatMessage, showToast]
  );

  const columns = [
    {
      Header: <FormattedMessage id="app.common.background" />,
      accessor: 'image',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <Image
            src={value || PlaceHolderImg}
            onError={onImageError}
            style={{ height: 50, objectFit: 'contain' }}
          />
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.title" />,
      accessor: 'title',
      Cell: useCallback(
        ({ cell: { value } }) => (
          <HighlightedText text={value} searchText={filters?.search || null} />
        ),
        [filters?.search]
      ),
    },
    {
      Header: <FormattedMessage id="app.common.editor" />,
      accessor: 'UserName',
      Cell: useCallback(
        ({ cell: { value }, row: { original } }) => (
          <HighlightedText text={`${value} ${original.UserSurname}`} />
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.area" />,
      accessor: 'unit',
      Cell: useCallback(
        ({ cell: { value }, row: { original } }) => (
          <span>
            <FormattedNumber
              value={Math.round(
                Number(original.width) * Number(original.height)
              )}
            />
            {value}²<br />
            <small>
              ({original.width} x {original.height})
            </small>
          </span>
        ),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.event" />,
      accessor: 'calendar_title',
    },
    {
      Header: <FormattedMessage id="app.common.avail" />,
      accessor: 'CountFree',
    },
    {
      Header: <FormattedMessage id="app.common.onHold" />,
      accessor: 'CountHold',
    },
    {
      Header: <FormattedMessage id="app.common.sold" />,
      accessor: 'CountSold',
    },
    {
      Header: <FormattedMessage id="app.common.createdDate" />,
      accessor: 'created_date',
      Cell: useCallback(
        ({ cell: { value } }) =>
          moment(value).format(Constants.DateFormats.APP.Moment.Common),
        []
      ),
    },
    {
      Header: <FormattedMessage id="app.common.status" />,
      accessor: 'status',
      disableNullControl: true,
      Cell: useCallback(
        ({ cell: { value } }) => (
          <>
            <span className={`legend-indicator bg-${getClassSuffix(value)}`} />
            <FormattedMessage id={getStatusMessageId(value)} />
          </>
        ),
        []
      ),
    },
    {
      accessor: 'id',
      Cell: useCallback(
        ({
          cell: {
            value,
            row: { original },
          },
        }) => {
          if (
            role === Constants.User.Types[15].id ||
            role === Constants.User.Types[17].id
          )
            return (
              <Dropdown align="end">
                <ButtonGroup>
                  <Button
                    variant="white"
                    size="sm"
                    as={Link}
                    to={`${AppRoutes.protected.FLOOR_PLAN_EDITOR_ASSISTANT_CHANGES.path.replace(
                      ':id',
                      value
                    )}`}
                  >
                    <i className="bi-eye me-1" />
                    <FormattedMessage id="app.common.view" />
                  </Button>
                  <ButtonGroup>
                    <Dropdown.Toggle
                      variant="white"
                      size="sm"
                      className="btn-icon dropdown-toggle-empty"
                    />
                  </ButtonGroup>
                </ButtonGroup>
                <Dropdown.Menu className="m-0 zi-9999" renderOnMount>
                  <Dropdown.Header>
                    <FormattedMessage id="app.common.actions" />
                  </Dropdown.Header>
                  <Dropdown.Item
                    onClick={() => {
                      onAddNewOrEdit(original);
                    }}
                  >
                    <i className="bi-box-arrow-up-right dropdown-item-icon" />
                    <span>
                      <FormattedMessage id="app.common.preview" />
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    onClick={() => {
                      onDeleteRow(value, original.title);
                    }}
                  >
                    <i className="bi-trash dropdown-item-icon text-danger" />
                    <span className="text-danger">
                      <FormattedMessage id="app.common.delete" />
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            );
          if (role === Constants.User.Types[19].id)
            return (
              <Button
                variant="white"
                size="sm"
                as={Link}
                to={`${AppRoutes.protected.FLOOR_PLAN_EDITOR.path.replace(
                  ':id',
                  value
                )}`}
              >
                <i className="bi-eye me-1" />
                <FormattedMessage id="app.common.view" />
              </Button>
            );
          return (
            <Button
              variant="white"
              size="sm"
              as={Link}
              to={`${AppRoutes.public.FLOOR_PLAN.path.replace(
                ':guid',
                original.guid
              )}`}
              target="_blank"
            >
              <i className="bi-box-arrow-up-right me-1" />
              <FormattedMessage id="app.common.preview" />
            </Button>
          );
        },
        [onAddNewOrEdit, onDeleteRow, role]
      ),
    },
  ];

  const filterFields = useMemo(
    () => [
      {
        cols: [
          {
            key: 'event',
            apiKey: 'calendar_id',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: calendarData,
                  getOptionValue: (option) => `${option.CalendarID}`,
                  getOptionLabel: (option) => option.Title,
                  isLoading: calendarDataIsLoading,
                  isClearable: true,
                  isMulti: false,
                  isSearchable: true,
                },
              },
            },
            schema: yup.string().nullable(),
          },
          {
            key: 'status',
            type: 'react-select',
            options: {
              controller: {
                props: {
                  options: [
                    { label: 'active', value: '1' },
                    { label: 'inactive', value: '0' },
                    { label: 'locked', value: '2' },
                  ],
                  formatOptionLabel: statusOptionLabel,
                  isClearable: true,
                  isMulti: false,
                  isSearchable: false,
                },
              },
            },
            schema: yup.string().nullable(),
          },
        ],
      },
    ],
    [calendarData, calendarDataIsLoading]
  );

  const parseSearchParams = useCallback(
    (isAPI) => {
      const getVal = (val, key) => {
        if (isAPI) {
          return val;
        }
        if (key === 'dateRange') {
          return moment(val).toDate();
        }
        return val;
      };

      const params = {};
      searchParams.forEach((value, key) => {
        let isArray = false;
        filterFields.forEach((row) => {
          row.cols.forEach((col) => {
            if (
              col.key === key &&
              (col.options?.controller?.props?.isMulti ||
                col.key === 'dateRange')
            ) {
              isArray = true;
            }
          });
        });

        if (!params[key]) {
          params[key] = !isArray ? getVal(value, key) : [getVal(value, key)];
        } else {
          params[key] = [
            ...(Array.isArray(params[key]) ? params[key] : [params[key]]),
            getVal(value, key),
          ];
        }
      });
      return params;
    },
    [searchParams, filterFields]
  );

  const handleSearch = (text) => {
    const params = parseSearchParams(true);
    if (text) {
      params.search = text;
    } else {
      delete params.search;
    }
    setSearchParams(params);
  };

  const onSearch = debounce((text) => {
    handleSearch(text);
  }, 100);

  useEffect(() => {
    const params = parseSearchParams(true);
    const nParams = params.search ? { search: params.search } : {};
    filterFields.forEach((row) => {
      row.cols.forEach((col) => {
        if (params[col.key]) {
          nParams[col.apiKey || col.key] = params[col.key];
        }
      });
    });
    setFilters(nParams);
  }, [parseSearchParams, filterFields]);

  useEffect(() => {
    const params = parseSearchParams();
    if (params.search) {
      delete params.search;
    }
    filterFormRef.current.reset(params);
  }, [parseSearchParams]);

  return (
    <div className="content container">
      <PageHeader
        className="d-block"
        title="floorPlans"
        breadcrumbData={{
          current: 'floorPlans',
        }}
      />
      <Row>
        <Col>
          <Card>
            <Card.Header className="card-header-content-md-between">
              <div className="mb-2 mb-md-0">
                <div className="input-group input-group-merge input-group-borderless">
                  <div className="input-group-prepend input-group-text">
                    <i className="bi-search" />
                  </div>
                  <Form.Control
                    type="search"
                    placeholder={formatMessage({
                      id: 'app.common.searchFloorPlans',
                    })}
                    defaultValue={parseSearchParams().search || ''}
                    onChange={(e) => {
                      if (e.target.value) {
                        onSearch(e.target.value);
                      } else {
                        handleSearch(e.target.value);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="d-grid d-sm-flex align-items-sm-center gap-2">
                <div id="datatableCounterInfo" style={{ display: 'none' }}>
                  <div className="d-flex align-items-center">
                    <span className="fs-5 me-3">
                      <span id="datatableCounter">0</span> Selected
                    </span>

                    <a className="btn btn-outline-danger btn-sm" href="#!">
                      <i className="bi-trash" /> Delete
                    </a>
                  </div>
                </div>

                <Button
                  variant="white"
                  size="sm"
                  className="dropdown-toggle"
                  onClick={() => {
                    setFiltersIsVisible(!filtersIsVisible);
                  }}
                >
                  <i className="bi-filter me-1" />
                  <FormattedMessage id="app.common.filters" />
                  {Object.keys(filters).filter((key) => key !== 'search')
                    .length > 0 && (
                    <Badge
                      bg="soft-dark"
                      className="text-dark rounded-circle ms-1"
                    >
                      {
                        Object.keys(filters).filter((key) => key !== 'search')
                          .length
                      }
                    </Badge>
                  )}
                </Button>
              </div>
            </Card.Header>

            <Collapse in={filtersIsVisible}>
              <div id="filter-collapse">
                <Card.Body>
                  <SmartForm
                    ref={filterFormRef}
                    fields={filterFields}
                    submitButtonText="applyFilters"
                    clearButtonText="clearFilters"
                    isFilterForm
                    disableApiKey
                    // defaultValues={parseSearchParams()}
                    /* defaultValues={{
                sources: [
                  { id: 'expedia', label: 'Expedia', color: '#ff0000' },
                  { id: 'facebook', label: 'Facebook', color: '#ff0000' },
                ],
              }} */
                    /* defaultValues={{
                dateRange: [
                  new Date(),
                  moment(new Date()).add('10', 'days').toDate(),
                ],
                dateRange2: [
                  new Date(),
                  moment(new Date()).add('5', 'days').toDate(),
                ],
              }} */
                    onSubmit={(formData) => {
                      const params = {};
                      if (formData) {
                        Object.keys(formData).forEach((key) => {
                          if (formData[key]) {
                            if (key === 'dateRange') {
                              params[key] = formData[key].map((item) =>
                                moment(item).format(Constants.DateFormats.API)
                              );
                            } else {
                              params[key] = formData[key];
                            }
                          }
                        });
                      }

                      if (parseSearchParams().search) {
                        params.search = parseSearchParams().search;
                      }

                      setSearchParams(params);
                    }}
                  />
                </Card.Body>
              </div>
            </Collapse>

            <SmartTable
              ref={tableRef}
              columns={columns}
              requestUrl={`/${service}/list_assistant_changes`}
              filters={filters}
              hoverable
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default FloorPlanChangeRequests;
