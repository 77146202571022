/* eslint-disable no-restricted-syntax */

import React, {
  useRef,
  useMemo,
  createRef,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  useStoreRoot,
  useStoreState,
  useStoreStateSetPromise,
  useStoreValue,
  useStoreStateValue,
  useStoreStateSetValue,
} from '@scena/react-store';
import { SceneItem } from 'scenejs';
import {
  Button,
  ButtonGroup,
  Offcanvas,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import SimpleBar from 'simplebar-react';
import { isNumber } from 'lodash';
import Viewport from './editorComponents/Viewport';
import {
  prefix,
  checkInput,
  getParentScenaElement,
  keyChecker,
  isArrayEquals,
} from './utils/utils';

import LayerManager, { createLayer } from './managers/LayerManager';
import KeyManager from './managers/KeyManager';
import HistoryManager from './managers/HistoryManager';
import ActionManager from './managers/ActionManager';
import MemoryManager from './managers/MemoryManager';

// import { TestIcon, AltTestIcon } from './iconsForMap';

import {
  $actionManager,
  $layerManager,
  $editor,
  $historyManager,
  $horizontalGuides,
  $infiniteViewer,
  $keyManager,
  $layers,
  $memoryManager,
  $moveable,
  $selectedLayers,
  $selecto,
  $verticalGuides,
  $zoom,
  $unitType,
  $searchResults,
  $searchResultsIndex,
} from './stores/stores';
import { $alt, $meta, $shift, $space } from './stores/keys';

import { GuidesManager } from './editorComponents/GuidesManager';
import { InfiniteViewerManager } from './editorComponents/InfiniteViewerManager';
import { SelectoManager } from './editorComponents/SelectoManager';
import { MoveableManager } from './editorComponents/MoveableManager';
import { registerHistoryTypes } from './managers/histories/histories';
import { readFiles } from './managers/FileManager';
import { ThemeContext } from '../../../../context/ThemeContext';
import {
  LeftToolbar,
  TopToolbar,
  RightToolbar,
  PreviewLeftToolbar,
} from './uis';
import {
  RULER_SIZE,
  DEFAULT_VIEWPORT_WIDTH,
  DEFAULT_VIEWPORT_HEIGHT,
  ALLOW_DROP_FILES,
  ZOOM_MIN,
  ZOOM_MAX,
  ZOOM_STEP,
  LAYER_TYPES,
  DEFAULT_BOOTH_SIZE,
  DEFAULT_CARPET_SIZE,
  DEFAULT_ICON_SIZE,
  DEFAULT_OTHER_SPACE_SIZE,
  DEFAULT_BOOTH_STYLE,
  DEFAULT_CARPET_STYLE,
  DEFAULT_BOOTH_CHILD_STYLE,
  DEFAULT_CARPET_CHILD_STYLE,
  DEFAULT_OTHER_SPACE_CHILD_STYLE,
  DEFAULT_OTHER_SPACE_STYLE,
  DEFAULT_BLOCK_SPACE_CHILD_STYLE,
  DEFAULT_BLOCK_SPACE_STYLE,
  DEFAULT_ICON_STYLE,
  BOOTH_STATUS_TYPES,
  DEFAULT_RECTANGLE_STYLE,
  DEFAULT_CIRCLE_STYLE,
  DEFAULT_TEXT_STYLE,
  DEFAULT_BLOCK_SPACE_SIZE,
  DEFAULT_TEXT_SIZE,
  DEFAULT_TEXT_EMPTY_VALUE,
} from './consts';
import Booth from './defaultComponents/Booth';
import Carpet from './defaultComponents/Carpet';
import Icon from './defaultComponents/Icon';
import OtherSpace from './defaultComponents/OtherSpace';
import BlockSpace from './defaultComponents/BlockSpace';
import Text from './defaultComponents/Text';
import { useBlockerConfirm, useConfirmModal } from '../../../../hooks';

/* function getTransformValues(transformString) {
  const matrix = new window.WebKitCSSMatrix(transformString);
  return matrix ? { x: matrix.m41, y: matrix.m42 } : { x: 0, y: 0 };
} */

function parsePxValue(value) {
  if (typeof value === 'string') {
    return parseFloat(value.replace('px', ''));
  }
  return value;
}

function getTransformValues(transformString) {
  const translateRegex = /translate\((-?\d+\.?\d*)px,\s*(-?\d+\.?\d*)px\)/;
  const rotateRegex = /rotate\((-?\d+\.?\d*)deg\)/;

  const translateMatches = translateRegex.exec(transformString);
  const rotateMatches = rotateRegex.exec(transformString);

  const translate = translateMatches
    ? { x: parseFloat(translateMatches[1]), y: parseFloat(translateMatches[2]) }
    : { x: 0, y: 0 };

  const rotate = rotateMatches ? parseFloat(rotateMatches[1]) : 0;

  return {
    translate,
    rotate,
  };
}

// Helper function to calculate the perpendicular axis (normal) to an edge

// Helper function to project a polygon onto an axis
function projectPolygon(axis, vertices) {
  let min = Number.MAX_VALUE;
  let max = -Number.MAX_VALUE;

  // Project each vertex of the polygon onto the axis
  vertices.forEach((vertex) => {
    // The projection is the dot product of the vertex with the axis
    const projection = vertex.x * axis.x + vertex.y * axis.y;
    min = Math.min(min, projection);
    max = Math.max(max, projection);
  });

  return { min, max };
}

// The main function to check SAT overlap
// Helper function to check if two projections overlap significantly
function significantOverlap(projection1, projection2) {
  const overlapAmount =
    Math.min(projection1.max, projection2.max) -
    Math.max(projection1.min, projection2.min);
  return overlapAmount > 1; // Only consider an overlap if it's more than 1px
}

function checkSATOverlap(verticesA, verticesB) {
  if (!verticesA || !verticesB) {
    throw new Error('Vertices arrays cannot be undefined');
  }

  // Compute edges for verticesA and verticesB
  const edgesA = verticesA.map((vertex, index) => {
    const nextVertex = verticesA[(index + 1) % verticesA.length];
    return { x: nextVertex.x - vertex.x, y: nextVertex.y - vertex.y };
  });

  const edgesB = verticesB.map((vertex, index) => {
    const nextVertex = verticesB[(index + 1) % verticesB.length];
    return { x: nextVertex.x - vertex.x, y: nextVertex.y - vertex.y };
  });

  // Combine all unique axes to test from both polygons
  const axes = [...edgesA, ...edgesB].map((edge) => ({
    x: -edge.y,
    y: edge.x,
  }));

  // Remove duplicate axes by converting them to strings and using a Set
  const uniqueAxes = Array.from(
    new Set(axes.map((axis) => `${axis.x},${axis.y}`))
  ).map((str) => {
    const [x, y] = str.split(',').map(Number);
    return { x, y };
  });

  // Check for a separating axis using significantOverlap instead of overlap
  for (const axis of uniqueAxes) {
    const projectionA = projectPolygon(axis, verticesA);
    const projectionB = projectPolygon(axis, verticesB);

    if (!significantOverlap(projectionA, projectionB)) {
      return false; // Separating axis found, no significant overlap
    }
  }

  return true; // No separating axis found, polygons significantly overlap
}

// Assuming that LAYER_TYPES and other constants are defined elsewhere in your code.

function calculateVertices(x, y, width, height, rotationAngle) {
  // Calculate the vertices of the rotated rectangle
  const angleRad = rotationAngle * (Math.PI / 180);
  const cx = x + width / 2;
  const cy = y + height / 2;
  const halfWidth = width / 2;
  const halfHeight = height / 2;

  const vertices = [
    { x: -halfWidth, y: -halfHeight },
    { x: halfWidth, y: -halfHeight },
    { x: halfWidth, y: halfHeight },
    { x: -halfWidth, y: halfHeight },
  ];

  return vertices.map((vertex) => {
    const rotatedX =
      cx + (vertex.x * Math.cos(angleRad) - vertex.y * Math.sin(angleRad));
    const rotatedY =
      cy + (vertex.x * Math.sin(angleRad) + vertex.y * Math.cos(angleRad));
    return { x: rotatedX, y: rotatedY };
  });
}

// ...getTransformValues and parsePxValue functions remain unchanged...

function hasOverlap(items) {
  const itemsWithVertices = items.map((item) => {
    if (
      item.type === LAYER_TYPES.booth ||
      item.type === LAYER_TYPES.blockSpace
    ) {
      const transform = getTransformValues(item.css.transform);
      const x = parsePxValue(item.css.left) + transform.translate.x;
      const y = parsePxValue(item.css.top) + transform.translate.y;
      const width = parsePxValue(item.css.width);
      const height = parsePxValue(item.css.height);
      const rotationAngle = transform.rotate;
      const vertices = calculateVertices(x, y, width, height, rotationAngle);
      return { ...item, vertices };
    }
    return item;
  });

  const overlappingPairs = [];

  for (let i = 0; i < itemsWithVertices.length; i += 1) {
    const item1 = itemsWithVertices[i];
    for (let j = i + 1; j < itemsWithVertices.length; j += 1) {
      const item2 = itemsWithVertices[j];
      if (item1.vertices && item2.vertices) {
        // Check for overlap
        if (checkSATOverlap(item1.vertices, item2.vertices)) {
          // Store the overlapping pair
          overlappingPairs.push({ item1, item2 });
        }
      }
    }
  }

  return overlappingPairs.length > 0 ? overlappingPairs : false; // Return pairs or false if none
}

// ...checkSATOverlap, projectPolygon, and overlap functions remain unchanged...

function EditorManager({
  width,
  height,
  background,
  data: initialData,
  onSave,
  onUnlock,
  onDecline,
  isPreview,
  isRestricted,
}) {
  const zoomScale = Math.max(DEFAULT_VIEWPORT_WIDTH / width, 10);
  const rulerSize = isPreview ? 0 : RULER_SIZE;

  const [zoomStepper, setZoomStepper] = useState(1);

  const { theme } = useContext(ThemeContext);
  const { formatMessage } = useIntl();

  const root = useStoreRoot();
  const editorRef = useRef();

  const historyManager = useMemo(() => new HistoryManager(editorRef), []);
  const actionManager = useMemo(() => new ActionManager(), []);
  const memoryManager = useMemo(() => new MemoryManager(), []);
  const layerManager = useMemo(() => new LayerManager(), []);
  const keyManager = useMemo(
    () => new KeyManager(root, actionManager),
    [root, actionManager]
  );

  const horizontalGuidesRef = useRef();
  const verticalGuidesRef = useRef();
  const infiniteViewerRef = useRef();
  const moveableRef = useRef();
  const selectoRef = useRef();
  const viewportRef = useRef();
  const editorElementRef = useRef();

  // declare global store
  useStoreValue($historyManager, historyManager);
  useStoreValue($actionManager, actionManager);
  useStoreValue($memoryManager, memoryManager);
  useStoreValue($layerManager, layerManager);
  useStoreValue($keyManager, keyManager);

  // declare global ui component
  useStoreValue($moveable, moveableRef);
  useStoreValue($selecto, selectoRef);
  useStoreValue($infiniteViewer, infiniteViewerRef);
  useStoreValue($horizontalGuides, horizontalGuidesRef);
  useStoreValue($verticalGuides, verticalGuidesRef);
  useStoreValue($editor, editorRef);

  // res
  const [isDirty, setIsDirty] = useState(false);
  const [showBackground, setShowBackground] = useState(true);
  const [showGrid, setShowGrid] = useState(true);
  const [layoutMargin, setLayoutMargin] = useState(0);

  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  const [previewSelectedItem, setPreviewSelectedItem] = useState();
  const [previewSearchValue, setPreviewSearchValue] = useState('');

  const handleCloseLeft = () => setShowLeft(false);
  const handleCloseRight = () => setShowRight(false);

  // const zoomStore = useStoreValue($zoom);
  const [zoom, setZoom] = useStoreState($zoom);
  const layerStore = useStoreValue($layers);
  const setSearchResults = useStoreStateSetValue($searchResults);
  const setSearchResultsIndex = useStoreStateSetValue($searchResultsIndex);

  const unitType = useStoreStateValue($unitType);

  const { isActive, onConfirm, resetConfirmation } = useBlockerConfirm(isDirty);
  const { confirm } = useConfirmModal({ confirmVariant: 'danger' });

  useEffect(() => {
    const showConfirm = async () => {
      const isSuccess = await confirm({
        title: 'unsavedChanges',
        confirmLabel: 'leavePage',
        message: <FormattedMessage id="app.common.unsavedChangesMessage" />,
      });
      if (isSuccess) {
        onConfirm();
      } else {
        resetConfirmation();
      }
    };
    if (isActive) {
      showConfirm();
    }
  }, [isActive, confirm, onConfirm, resetConfirmation]);

  const getJSXComponent = (type) => {
    let component = Booth;
    switch (type) {
      case LAYER_TYPES.booth:
        component = Booth;
        break;

      case LAYER_TYPES.carpet:
        component = Carpet;
        break;

      case LAYER_TYPES.otherSpace:
        component = OtherSpace;
        break;

      case LAYER_TYPES.blockSpace:
        component = BlockSpace;
        break;

      case LAYER_TYPES.icon:
        component = Icon;
        break;

      case LAYER_TYPES.rectangle:
      case LAYER_TYPES.circle:
        component = 'div';
        break;

      case LAYER_TYPES.text:
        component = Text;
        break;

      default:
        component = Booth;
        break;
    }
    return component;
  };

  const setInitialLayers = useCallback(() => {
    if (initialData?.output?.length > 0) {
      const nLayers = initialData.output.map(
        ({ id, fpeData, innerText, title, type, lock, jsx, css }) => {
          const Component = getJSXComponent(type);
          const props = { ...jsx.props, style: css };
          if (title !== undefined && title !== null) {
            props.title = title;
          }
          let JSX;
          switch (type) {
            case 'booth':
              JSX = (
                <Component
                  {...props}
                  isPreview={isPreview}
                  fpeData={fpeData}
                  onClick={
                    isPreview
                      ? () => {
                          if (fpeData?.exhibitors?.length === 1) {
                            setPreviewSearchValue('');
                            setPreviewSelectedItem({
                              mapId: initialData?.id,
                              exhibitor: fpeData?.exhibitors?.[0],
                              layers: layerManager.layers,
                              additionalData: {
                                title,
                                width: css.width,
                                height: css.height,
                              },
                            });
                          } else if (fpeData?.exhibitors?.length > 1) {
                            setPreviewSelectedItem(null);
                            setPreviewSearchValue(`${title}`);
                          } else {
                            setPreviewSearchValue('');
                            setPreviewSelectedItem({
                              mapId: initialData?.id,
                              exhibitor: null,
                              layers: layerManager.layers,
                              additionalData: {
                                title,
                                width: css.width,
                                height: css.height,
                              },
                            });
                          }
                        }
                      : undefined
                  }
                />
              );
              break;
            case 'text':
              JSX = (
                <Component
                  {...props}
                  innerText={innerText}
                  contentEditable={!isPreview}
                />
              );
              break;

            default:
              JSX = <Component {...props} />;
              break;
          }
          return {
            id,
            fpeData,
            innerText,
            title,
            type,
            lock,
            jsx: JSX,
            scope: [],
            item: new SceneItem(),
            ref: createRef(),
          };
        }
      );

      layerManager.setLayers(nLayers || []);
      layerStore.update(nLayers || []);
    }
  }, [layerManager, layerStore, initialData, isPreview]);

  useEffect(() => {
    setInitialLayers();
    layerManager.calculateLayers();
  }, [layerManager, setInitialLayers]);

  const setLayersPromise = useStoreStateSetPromise($layers);
  const selectedLayersStore = useStoreValue($selectedLayers);
  const setSelectedLayersPromise = useStoreStateSetPromise($selectedLayers);

  const getSelectedLayers = useCallback(
    () => selectedLayersStore.value,
    [selectedLayersStore.value]
  );

  const setSelectedLayers = useCallback(
    (nextLayers, isRestore) => {
      const prevLayers = getSelectedLayers();

      if (isArrayEquals(prevLayers, nextLayers)) {
        return Promise.resolve(false);
      }
      return setSelectedLayersPromise(nextLayers).then((complete) => {
        if (!complete) {
          return false;
        }
        layerManager.calculateLayers();

        if (!isRestore) {
          const prevs = prevLayers;
          const nexts = nextLayers;

          historyManager.addHistory('selectTargets', { prevs, nexts });
        }

        selectoRef.current?.setSelectedTargets(
          layerManager.toTargetList(nextLayers).flatten()
        );
        actionManager.act('set.selected.layers');
        return true;
      });
    },
    [
      actionManager,
      getSelectedLayers,
      historyManager,
      layerManager,
      setSelectedLayersPromise,
    ]
  );

  const clearSearch = useCallback(() => {
    layerManager.layers.forEach((item) => {
      const nItem = item;
      nItem.ref.current.style.cssText += 'opacity:1';
    });
    setPreviewSelectedItem(null);
    setSearchResultsIndex(0);
    setSearchResults([]);
  }, [layerManager.layers, setSearchResults, setSearchResultsIndex]);

  const highlightLayer = useCallback(
    (
      results,
      index,
      options = { opacity: 0.1, scroll: true, markAll: true }
    ) => {
      if (results.length > 0) {
        if (options.opacity) {
          layerManager?.layers?.forEach((item) => {
            const nItem = item;
            nItem.ref.current.style.cssText += `opacity:${options.opacity}`;
          });
          results.forEach((item, i) => {
            const nItem = item;
            nItem.ref.current.style.cssText +=
              i !== index && options.markAll ? 'opacity:0.5' : 'opacity:1';
          });
        }

        if (options.scroll) {
          const frame = layerManager?.getFrame(results[index]);
          let x =
            Number(frame.properties.left) +
            Number(
              frame.properties.transform.translate.value[0].replace('px', '')
            );
          let y =
            Number(frame.properties.top) +
            Number(
              frame.properties.transform.translate.value[1].replace('px', '')
            );

          const frameWidth = isNumber(frame.properties.width)
            ? frame.properties.width
            : Number(frame.properties.width.replace('px', ''));
          const frameHeight = isNumber(frame.properties.height)
            ? frame.properties.height
            : Number(frame.properties.height.replace('px', ''));
          x -=
            infiniteViewerRef.current.containerElement.clientWidth / zoom / 2 -
            frameWidth / 2;
          y -=
            infiniteViewerRef.current.containerElement.clientHeight / zoom / 2 -
            frameHeight / 2;

          infiniteViewerRef.current.scrollTo(
            x,
            y,
            // zoom,
            { absolute: false, duration: 500 }
          );
        }
      } else {
        layerManager?.layers?.forEach((item) => {
          const nItem = item;
          if (nItem.ref.current?.style) {
            nItem.ref.current.style.cssText += 'opacity:1';
          }
        });
      }
    },
    [layerManager, zoom]
  );

  const onInteraction = useCallback(() => {
    clearSearch();
  }, [clearSearch]);

  const onBlur = useCallback(
    (e) => {
      const { target } = e;

      if (!checkInput(target)) {
        return;
      }
      const parentTarget = getParentScenaElement(target);

      if (!parentTarget) {
        return;
      }

      if (!parentTarget.isContentEditable) {
        return;
      }

      const layer = layerManager.getLayerByElement(parentTarget);

      const nextText = parentTarget.innerText;

      historyManager.addHistory('changeText', {
        layer,
        prev: layer.innerText,
        next: nextText,
      });
      layer.innerText = nextText;
    },
    [historyManager, layerManager]
  );

  const changeLayers = useCallback(
    (nLayers, groups = layerManager.groups) => {
      layerManager.setLayers(nLayers, groups);
      layerManager.calculateLayers();
      return setLayersPromise(nLayers);
    },
    [layerManager, setLayersPromise]
  );

  const setLayers = useCallback(
    (nLayers, groups = layerManager.groups) => {
      layerManager.setLayers(nLayers, groups);
      return setLayersPromise(nLayers).then((complete) => {
        layerManager.calculateLayers();
        return complete;
      });
    },
    [layerManager, setLayersPromise]
  );

  const addLayer = useCallback(
    (style, type, data) => {
      const maxId =
        layerManager.layers.length > 0
          ? Math.max(
              ...layerManager.layers.map((o) =>
                Number(o.title) ? Number(o.title) : 0
              )
            )
          : 0;
      let title;
      let jsx;
      let fpeData;
      let innerText;

      const Component = getJSXComponent(type);

      switch (type) {
        case LAYER_TYPES.booth:
          title = maxId + 1;
          fpeData = { status: BOOTH_STATUS_TYPES.avail };
          jsx = (
            <Component
              title={title}
              style={{ ...DEFAULT_BOOTH_STYLE, ...style }}
              childStyle={{ ...DEFAULT_BOOTH_CHILD_STYLE }}
            />
          );
          break;

        case LAYER_TYPES.carpet:
          title = formatMessage({
            id: `app.common.${data.id || LAYER_TYPES.carpet}`,
          });
          jsx = (
            <Component
              title={title}
              style={{ ...DEFAULT_CARPET_STYLE, ...style }}
              childStyle={{ ...DEFAULT_CARPET_CHILD_STYLE }}
            />
          );
          break;

        case LAYER_TYPES.otherSpace:
          title = formatMessage({
            id: `app.common.${data.id || LAYER_TYPES.otherSpace}`,
          });
          jsx = (
            <Component
              title={title}
              style={{ ...DEFAULT_OTHER_SPACE_STYLE, ...style }}
              childStyle={{ ...DEFAULT_OTHER_SPACE_CHILD_STYLE }}
            />
          );
          break;

        case LAYER_TYPES.blockSpace:
          title = formatMessage({
            id: `app.common.${data.id || LAYER_TYPES.blockSpace}`,
          });
          jsx = (
            <Component
              title={title}
              style={{
                ...DEFAULT_BLOCK_SPACE_STYLE,
                ...style,
              }}
              childStyle={{ ...DEFAULT_BLOCK_SPACE_CHILD_STYLE }}
            />
          );
          break;

        case LAYER_TYPES.icon:
          title = formatMessage({
            id: `app.helpers.floorPlans.icons.${data.id}`,
          });
          jsx = (
            <Component
              id={data.id}
              style={{ ...DEFAULT_ICON_STYLE, ...style }}
            />
          );
          break;

        case LAYER_TYPES.rectangle:
          title = undefined;
          jsx = (
            <Component
              data-type={LAYER_TYPES.rectangle}
              style={{ ...DEFAULT_RECTANGLE_STYLE, ...style }}
            />
          );
          break;

        case LAYER_TYPES.circle:
          title = undefined;
          jsx = (
            <Component
              data-type={LAYER_TYPES.circle}
              style={{ ...DEFAULT_CIRCLE_STYLE, ...style }}
            />
          );
          break;

        case LAYER_TYPES.text:
          title = undefined;
          innerText = formatMessage({
            id: `app.common.${DEFAULT_TEXT_EMPTY_VALUE}`,
          });
          jsx = <Component style={{ ...DEFAULT_TEXT_STYLE, ...style }} />;
          break;

        default:
          jsx = (
            <Component
              title={title}
              style={style}
              childStyle={{ ...DEFAULT_BOOTH_CHILD_STYLE }}
            />
          );
          break;
      }

      const layer = createLayer({
        fpeData,
        innerText,
        title,
        type,
        scope: [],
        jsx,
        item: new SceneItem(),
        ref: createRef(),
      });

      // const prevs = layerManager.layers;
      const nexts = [...layerManager.layers, layer];

      setLayers(nexts);

      /* setLayers(nexts).then(() => {
      setTimeout(() => {
        setSelectedLayers([layer]);
      }, 1);
      historyManager.addHistory('addLayers', { prevs, nexts });
    }); */
    },
    [formatMessage, layerManager.layers, setLayers]
  );

  const duplicateLayer = useCallback(
    ({ style, type, moveable }) => {
      addLayer(style, type, { moveable });
    },
    [addLayer]
  );

  const removeLayers = useCallback(() => {
    const prevs = layerManager.layers;
    const selectedLayers = getSelectedLayers();

    const nexts = layerManager.layers.filter((layer) => {
      const founded = selectedLayers.find(
        (selectedLayer) => selectedLayer.id === layer.id
      );

      if (!founded) {
        return true;
      }

      return false;
    });

    setLayers(nexts);

    historyManager.addHistory('removeLayers', { prevs, nexts });
  }, [getSelectedLayers, historyManager, layerManager.layers, setLayers]);

  const getMoveable = () => moveableRef.current;

  const move = useCallback((deltaX, deltaY) => {
    if (!getMoveable().props.draggable) {
      return;
    }
    getMoveable().request('draggable', { deltaX, deltaY }, true);
  }, []);

  const changeLayerOrder = useCallback(
    (isBackwards, isAll) => {
      const selectedLayers = getSelectedLayers();

      let prevIndex = 0;
      const nexts = layerManager.layers.filter((layer, index) => {
        const founded = selectedLayers.find(
          (selectedLayer) => selectedLayer.id === layer.id
        );

        if (!founded) {
          return true;
        }

        if (isBackwards) {
          prevIndex = index > 0 ? index - selectedLayers.length : 0;
        } else {
          prevIndex = index + selectedLayers.length;
        }

        return false;
      });

      if (isAll) {
        if (isBackwards) {
          prevIndex = 0;
        } else {
          prevIndex = nexts.length;
        }
      }

      nexts.splice(prevIndex, 0, ...selectedLayers);

      changeLayers(nexts);
    },
    [changeLayers, getSelectedLayers, layerManager.layers]
  );

  const bringForwardLayers = useCallback(() => {
    changeLayerOrder(false);
  }, [changeLayerOrder]);

  const sendBackwardsLayers = useCallback(() => {
    changeLayerOrder(true);
  }, [changeLayerOrder]);

  const bringToFrontLayers = useCallback(() => {
    changeLayerOrder(false, true);
  }, [changeLayerOrder]);

  const sendToBackLayers = useCallback(() => {
    changeLayerOrder(true, true);
  }, [changeLayerOrder]);

  const unlockMap = useCallback(() => {
    const dataToSave = layerManager.layers.map((layer) => {
      const { id, fpeData, innerText, title, type, lock, jsx } = layer;
      const css = layerManager.getFrame(layer).toCSSObject();
      return {
        id,
        fpeData,
        innerText,
        title,
        type,
        lock,
        jsx,
        css,
      };
    });

    setIsDirty(false);
    onUnlock(dataToSave);
  }, [onUnlock, layerManager]);

  const exportLayers = useCallback(() => {
    const dataToSave = layerManager.layers.map((layer) => {
      const { id, fpeData, innerText, title, type, lock, jsx } = layer;
      const css = layerManager.getFrame(layer).toCSSObject();
      return {
        id,
        fpeData,
        innerText,
        title,
        type,
        lock,
        jsx,
        css,
      };
    });

    const overlappingItems = hasOverlap(dataToSave);

    if (overlappingItems) {
      const showConfirm = async () => {
        await confirm({
          title: 'Overlap(s) Detected',
          confirmLabel: 'OK',
          confirmVariant: 'primary',
          hideCancelLabel: true,
          message: (
            <>
              <FormattedMessage id="app.common.overlapDetectedMessage" />
              <br />
              <br />
              {overlappingItems.length > 0 &&
                overlappingItems.map(({ item1, item2 }) => (
                  <p key={`${item1.title}-${item2.title}`}>
                    <FormattedMessage id="app.common.overlapDetectedBetween" />{' '}
                    {item1.name || item1.title || 'Unnamed item'}{' '}
                    <FormattedMessage id="app.common.and" />{' '}
                    {item2.name || item2.title || 'Unnamed item'}.
                  </p>
                ))}
            </>
          ),
        });
      };
      showConfirm();
      return;
    }

    setIsDirty(false);
    onSave(dataToSave);
  }, [onSave, layerManager, confirm]);

  editorRef.current = useMemo(
    () => ({
      editorElementRef,
      historyManager,
      actionManager,
      memoryManager,
      layerManager,
      keyManager,
      moveableRef,
      selectoRef,
      viewportRef,
      changeLayers,
      setLayers,
      getSelectedLayers,
      setSelectedLayers,
      duplicateLayer,
      addLayer,
      removeLayers,
      bringForwardLayers,
      sendBackwardsLayers,
      bringToFrontLayers,
      sendToBackLayers,
      exportLayers,
      unlockMap,
      toggleBackground: () => {
        setShowBackground(!showBackground);
      },
      toggleGrid: () => {
        setShowGrid(!showGrid);
      },
      clearSearch,
      highlightLayer,
      onSave,
      onUnlock,
      onDecline,
    }),
    [
      actionManager,
      addLayer,
      bringForwardLayers,
      bringToFrontLayers,
      changeLayers,
      duplicateLayer,
      getSelectedLayers,
      historyManager,
      keyManager,
      layerManager,
      memoryManager,
      removeLayers,
      sendBackwardsLayers,
      sendToBackLayers,
      setLayers,
      setSelectedLayers,
      showBackground,
      showGrid,
      exportLayers,
      unlockMap,
      clearSearch,
      highlightLayer,
      onSave,
      onUnlock,
      onDecline,
    ]
  );

  useEffect(() => {
    const onUpdate = () => {
      setIsDirty(true);
      requestAnimationFrame(() => {
        actionManager.act('get.rect', {
          rect: moveableRef.current.getRect(),
        });
      });
    };
    actionManager.on('render.end', onUpdate);
    actionManager.on('changed.targets', onUpdate);
    actionManager.on('update.rect', onUpdate);

    /* actionManager.on('request.toogle.background', () => {
      const bgLayer = root.get($layers).find((item) => item.type === 'bg');
      const frame = layerManager.getFrame(bgLayer);
      const prev = frame.toCSSObject();
      const isVisible = prev.display !== 'none';

      frame.set('display', isVisible ? 'none' : 'block');

      bgLayer.ref.current.style.cssText += frame.toCSSText();
    }); */

    actionManager.on('select.all', (e) => {
      e.inputEvent?.preventDefault();
      const nLayers = root.get($layers);

      const childs = layerManager.selectSameDepthChilds(
        [],
        nLayers.map((layer) => layer.ref.current),
        []
      );

      setSelectedLayers(layerManager.toLayerGroups(childs));
    });
    actionManager.on('request.history.undo', (e) => {
      e.inputEvent?.preventDefault();
      historyManager.undo();
    });
    actionManager.on('request.history.redo', (e) => {
      e.inputEvent?.preventDefault();
      historyManager.redo();
    });

    actionManager.on('remove.targets', () => {
      const sLayers = getSelectedLayers();

      if (
        isRestricted &&
        sLayers.find((item) => item.type !== LAYER_TYPES.booth)
      ) {
        const showConfirm = async () => {
          await confirm({
            title: 'warning',
            hideCancelLabel: true,
            confirmLabel: 'ok',
            confirmVariant: 'light',
            message: (
              <FormattedMessage id="app.common.editorDeleteItemPermissionMessage" />
            ),
          });
        };
        showConfirm();
        return;
      }
      removeLayers();
    });

    actionManager.on('move.up', (e) => {
      e.inputEvent?.preventDefault();
      move(0, -1);
    });

    actionManager.on('move.left', (e) => {
      e.inputEvent?.preventDefault();
      move(-1, 0);
    });
    actionManager.on('move.right', (e) => {
      e.inputEvent?.preventDefault();
      move(1, 0);
    });
    actionManager.on('move.down', (e) => {
      e.inputEvent?.preventDefault();
      move(0, 1);
    });

    // register key
    keyManager.toggleState(['shift'], $shift, keyChecker);
    keyManager.toggleState(['space'], $space, keyChecker);
    keyManager.toggleState(['meta'], $meta, keyChecker);
    keyManager.toggleState(['alt'], $alt, keyChecker);

    // action down
    keyManager.actionDown(['left'], 'move.left');
    keyManager.actionDown(['right'], 'move.right');
    keyManager.actionDown(['up'], 'move.up');
    keyManager.actionDown(['down'], 'move.down');
    // TODO: window key
    keyManager.actionDown(['meta', 'a'], 'select.all');

    // action up
    keyManager.actionUp(['delete'], 'remove.targets');
    keyManager.actionUp(['backspace'], 'remove.targets');

    keyManager.actionDown(['meta', 'z'], 'request.history.undo');
    keyManager.actionDown(['meta', 'shift', 'z'], 'request.history.redo');

    // register default events
    const onResize = () => {
      horizontalGuidesRef.current.resize();
      verticalGuidesRef.current.resize();
    };
    const startId = requestAnimationFrame(() => {
      onResize();
      infiniteViewerRef.current.scrollCenter();
    });
    registerHistoryTypes(historyManager);
    window.addEventListener('resize', onResize);

    return () => {
      layerManager.set([], []);
      historyManager.clear();
      actionManager.off();
      keyManager.destroy();
      cancelAnimationFrame(startId);
      window.removeEventListener('resize', onResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const header = document.getElementsByTagName('header')[0];
    const footer = document.getElementsByClassName('footer-offset')[0];

    if (header && footer) {
      const footerStyle =
        footer.currentStyle || window.getComputedStyle(footer);

      const margin =
        header.clientHeight +
        parseInt(footerStyle.marginBottom.replace('px', ''), 10);
      setLayoutMargin(margin);
    }
  }, []);

  const canvasBgColor = theme === 'dark' ? '#f2f2f2' : '#f2f2f2';

  const rulerBgColor = theme === 'dark' ? '#363a3d' : '#f9fafc';
  const rulerTextColor = theme === 'dark' ? '#c5c8cc' : '#677788';
  const rulerLineColor = theme === 'dark' ? '#677788' : '#677788';
  const rulerSelectedBackgroundColor = theme === 'dark' ? '#292e33' : '#cccccc';

  const viewportWidth = width || DEFAULT_VIEWPORT_WIDTH;
  const viewportHeight = height || DEFAULT_VIEWPORT_HEIGHT;

  const onInfiniteViewerZoom = useCallback(
    (value) => {
      let nValue = value / zoomScale;
      if (nValue <= ZOOM_MIN) {
        nValue = ZOOM_MIN;
      } else if (nValue >= ZOOM_MAX) {
        nValue = ZOOM_MAX;
      }

      setZoomStepper(nValue);
    },
    [zoomScale]
  );

  useEffect(() => {
    const calculatedZoom = zoomStepper * zoomScale;
    setZoom(calculatedZoom);
  }, [zoomScale, zoomStepper, setZoom]);

  const onDrop = useCallback(
    (e) => {
      e.preventDefault();

      let dataTransferData = e.dataTransfer.getData('text');
      dataTransferData = dataTransferData?.split('|');

      const infiniteViewer = infiniteViewerRef.current;
      const viewportElement = infiniteViewer.getViewport();
      const { left, top } = viewportElement.getBoundingClientRect();
      const { clientX, clientY } = e;
      const offsetPosition = [(clientX - left) / zoom, (clientY - top) / zoom];

      if (dataTransferData?.[0] === 'sceneItem') {
        const [, type, id] = dataTransferData;

        let layerWidth;
        let layerHeight;
        switch (type) {
          case LAYER_TYPES.booth:
            layerWidth = DEFAULT_BOOTH_SIZE.width;
            layerHeight = DEFAULT_BOOTH_SIZE.height;
            break;

          case LAYER_TYPES.carpet:
            layerWidth = DEFAULT_CARPET_SIZE.width;
            layerHeight = DEFAULT_CARPET_SIZE.height;
            break;

          case LAYER_TYPES.otherSpace:
            layerWidth = DEFAULT_OTHER_SPACE_SIZE.width;
            layerHeight = DEFAULT_OTHER_SPACE_SIZE.height;
            break;

          case LAYER_TYPES.blockSpace:
            layerWidth = DEFAULT_BLOCK_SPACE_SIZE.width;
            layerHeight = DEFAULT_BLOCK_SPACE_SIZE.height;
            break;

          case LAYER_TYPES.icon:
            layerWidth = DEFAULT_ICON_SIZE.width;
            layerHeight = DEFAULT_ICON_SIZE.height;
            break;

          case LAYER_TYPES.text:
            layerWidth = DEFAULT_TEXT_SIZE.width;
            layerHeight = DEFAULT_TEXT_SIZE.height;
            break;

          default:
            layerWidth = DEFAULT_BOOTH_SIZE.width;
            layerHeight = DEFAULT_BOOTH_SIZE.height;
            break;
        }

        addLayer(
          {
            width: `${layerWidth}px`,
            height: `${layerHeight}px`,
            transform: `translate(${Math.round(
              offsetPosition[0] - layerWidth / 2
            )}px, ${Math.round(offsetPosition[1] - layerHeight / 2)}px)`,
          },
          dataTransferData[1],
          { id }
        );
        return;
      }
      if (ALLOW_DROP_FILES) {
        readFiles(e, offsetPosition).then((result) => {
          if (result.layers) {
            setLayers(
              [...layerManager.layers, ...result.layers],
              [...layerManager.groups, ...result.groups]
            );
          }
        });
      }
    },
    [layerManager.layers, layerManager.groups, setLayers, zoom, addLayer]
  );

  const editorMemo = useMemo(
    () => (
      <div
        tabIndex={0}
        role="button"
        ref={editorElementRef}
        className={`${prefix('editor')} position-relative w-100 h-100`}
        style={{ cursor: 'default' }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={onDrop}
        onMouseDown={() => {
          onInteraction();
        }}
      >
        {isPreview && (
          <div
            tabIndex={0}
            role="button"
            className="position-absolute d-flex d-lg-none align-items-center justify-content-center zi-1"
            style={{
              width: RULER_SIZE * 1.5,
              height: RULER_SIZE * 1.5,
              backgroundColor: rulerBgColor,
            }}
            onClick={() => {
              setShowLeft(true);
            }}
            onKeyDown={() => {}}
          >
            alsdjlaksjd
            <i className="bi-list fs-1" />
          </div>
        )}
        <div
          tabIndex={0}
          role="button"
          className={`${prefix(
            'reset'
          )} position-absolute d-flex align-items-center justify-content-center zi-1`}
          style={{
            width: RULER_SIZE,
            height: RULER_SIZE,
            backgroundColor: rulerBgColor,
            borderRight: isPreview ? undefined : `1px solid ${rulerLineColor}`,
            borderBottom: isPreview ? undefined : `1px solid ${rulerLineColor}`,
            right: isPreview ? 0 : undefined,
          }}
          onClick={() => {
            infiniteViewerRef.current.scrollCenter({
              duration: 500,
              absolute: true,
            });
          }}
          onKeyDown={() => {}}
        >
          <div>
            <div className="position-absolute top-50 start-50 translate-middle">
              <i
                className="bi-arrows-angle-contract d-inline-block"
                style={{ transform: 'rotate(45deg)' }}
              />
            </div>
            <div className="position-absolute top-50 start-50 translate-middle">
              <i
                className="bi-arrows-angle-contract d-inline-block"
                style={{ transform: 'rotate(135deg)' }}
              />
            </div>
            <div className="position-absolute top-50 start-50 translate-middle">
              <i className="bi-dot" />
            </div>
          </div>
        </div>
        <GuidesManager
          ref={horizontalGuidesRef}
          type="horizontal"
          size={rulerSize}
          backgroundColor={rulerBgColor}
          textColor={rulerTextColor}
          lineColor={rulerLineColor}
          selectedBackgroundColor={rulerSelectedBackgroundColor}
          viewportWidth={viewportWidth}
          viewportHeight={viewportHeight}
        />
        <GuidesManager
          ref={verticalGuidesRef}
          type="vertical"
          size={rulerSize}
          backgroundColor={rulerBgColor}
          textColor={rulerTextColor}
          lineColor={rulerLineColor}
          selectedBackgroundColor={rulerSelectedBackgroundColor}
          viewportWidth={viewportWidth}
          viewportHeight={viewportHeight}
        />
        <InfiniteViewerManager
          ref={infiniteViewerRef}
          style={{
            width: `calc(100% - ${rulerSize}px)`,
            height: `calc(100% - ${rulerSize}px)`,
            top: rulerSize,
            left: rulerSize,
          }}
          zoomMin={ZOOM_MIN * zoomScale}
          zoomMax={ZOOM_MAX * zoomScale}
          onZoom={onInfiniteViewerZoom}
        >
          <Viewport
            ref={viewportRef}
            onBlur={onBlur}
            showGrid={showGrid}
            isPreview={isPreview}
            style={{
              width: viewportWidth,
              height: viewportHeight,
              backgroundColor: '#fff',
              backgroundImage:
                background && showBackground ? `url(${background})` : 'none',
              backgroundSize: 'cover',
            }}
          >
            {!isPreview && (
              <MoveableManager ref={moveableRef} isRestricted={isRestricted} />
            )}
          </Viewport>
        </InfiniteViewerManager>
        {!isPreview && (
          <SelectoManager ref={selectoRef} isRestricted={isRestricted} />
        )}
      </div>
    ),
    [
      onBlur,
      onDrop,
      rulerBgColor,
      rulerLineColor,
      rulerSelectedBackgroundColor,
      rulerTextColor,
      viewportWidth,
      viewportHeight,
      onInfiniteViewerZoom,
      zoomScale,
      showBackground,
      background,
      showGrid,
      isPreview,
      rulerSize,
      onInteraction,
      isRestricted,
    ]
  );

  const bottomMemo = useMemo(
    () => (
      <div
        className="position-absolute"
        style={{ zIndex: 1, left: rulerSize + 10, bottom: 5, right: 10 }}
      >
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <ButtonGroup className="mb-2">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  <FormattedMessage id="app.common.zoomOut" />
                </Tooltip>
              }
            >
              <Button
                size="sm"
                variant="light"
                className="px-2"
                onClick={() => {
                  const nZoom =
                    ((Math.round(zoomStepper * 10) / 10 - ZOOM_STEP) * 100) /
                    100;

                  // const nZoom = zoom - ZOOM_STEP;

                  if (ZOOM_MIN <= nZoom) {
                    setZoomStepper(nZoom);
                  }
                }}
              >
                <i className="bi-zoom-out" />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>1:1</Tooltip>}>
              <Button
                size="sm"
                variant="light"
                className="px-2"
                onClick={() => {
                  setZoomStepper(1);
                }}
              >
                {`${Math.round(zoomStepper * 100)}%`}
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  <FormattedMessage id="app.common.zoomIn" />
                </Tooltip>
              }
            >
              <Button
                size="sm"
                variant="light"
                className="px-2"
                onClick={() => {
                  const nZoom =
                    ((Math.round(zoomStepper * 10) / 10 + ZOOM_STEP) * 100) /
                    100;
                  // const nZoom = zoom + ZOOM_STEP;
                  if (ZOOM_MAX >= nZoom) {
                    setZoomStepper(nZoom);
                  }
                }}
              >
                <i className="bi-zoom-in" />
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
          <Button
            size="sm"
            as="div"
            variant="light"
            className="shadow-none mb-2"
            disabled
            style={{ cursor: 'default' }}
            role="none"
          >
            <FormattedNumber value={Math.round(width * height)} />
            {unitType}²
          </Button>
        </div>
      </div>
    ),
    [zoomStepper, height, unitType, width, rulerSize]
  );

  /*
  const extractInt = (t) => {
    const e = /(-?\d+)/g.exec(t);
    return e === null || e[1] === null ? null : parseInt(e[1], 10);
  };

  const l = (t, e) => {
    let i;
    for (i = `${t}`; i.length < e; ) {
      i = `0${i}`;
    }
    return i;
  };

  const getNextBoothName = (text, e = false) => {
    const extractedInt = extractInt(text);
    let result = '';
    if (extractedInt) {
      const s = Math.abs(extractedInt).toString();
      if (e) {
        result = text.replace(s, l(extractedInt + 1, s.length));
      }
    } else {
      result = `${text} 1`;
    }

    return result;
  };
  */

  return (
    <div className="splitted-content-main position-relative border-bottom">
      {isPreview && initialData?.id && (
        <Offcanvas
          show={showLeft}
          onHide={handleCloseLeft}
          responsive="lg"
          className="splitted-content-small splitted-content-bordered d-flex flex-column h-100 bg-light"
          style={{ minHeight: 'auto' }}
        >
          <Offcanvas.Body className="p-0">
            <PreviewLeftToolbar
              layoutMargin={layoutMargin}
              mapId={initialData?.id}
              selectedItem={previewSelectedItem}
              setSelectedItem={setPreviewSelectedItem}
              searchValue={previewSearchValue}
              setSearchValue={setPreviewSearchValue}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {!isPreview && (
        <Offcanvas
          show={showLeft}
          onHide={handleCloseLeft}
          responsive="lg"
          className="splitted-content-mini splitted-content-bordered d-flex flex-column h-100 bg-light"
          style={{ minHeight: 'auto' }}
        >
          <Offcanvas.Body>
            <SimpleBar
              style={{
                width: '100%',
                height: `calc(100vh - ${layoutMargin}px)`,
              }}
            >
              <LeftToolbar
                iconFillColor={theme === 'dark' ? '#CBC8CC' : '#677788'}
                isRestricted={isRestricted}
              />
            </SimpleBar>
          </Offcanvas.Body>
        </Offcanvas>
      )}
      <div
        className="flex-grow-1 position-relative"
        style={{
          height: `calc(100vh - ${layoutMargin}px)`,
          backgroundColor: canvasBgColor,
        }}
      >
        <div className="d-flex flex-column w-100 h-100">
          {!isPreview && (
            <div className="bg-light px-3 border-bottom">
              <TopToolbar isRestricted={isRestricted} />
            </div>
          )}
          {bottomMemo}
          {editorMemo}
        </div>
      </div>
      {!isPreview && (
        <Offcanvas
          show={showRight}
          onHide={handleCloseRight}
          responsive="xl"
          placement="end"
          className="splitted-content-small splitted-content-bordered d-flex flex-column h-100 bg-light zi-1"
          style={{ minHeight: 'auto' }}
        >
          <Offcanvas.Body>
            <SimpleBar
              style={{
                width: '100%',
                height: `calc(100vh - ${layoutMargin}px)`,
              }}
            >
              <RightToolbar data={initialData} isRestricted={isRestricted} />
            </SimpleBar>
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </div>
  );
}

EditorManager.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  background: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any),
  onSave: PropTypes.any,
  onUnlock: PropTypes.any,
  onDecline: PropTypes.any,
  isPreview: PropTypes.bool,
  isRestricted: PropTypes.bool,
};

EditorManager.defaultProps = {
  width: 800,
  height: 600,
  background: null,
  data: null,
  onSave: false,
  onUnlock: false,
  onDecline: false,
  isPreview: false,
  isRestricted: false,
};

export default EditorManager;
